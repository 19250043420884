import * as React from 'react';
import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useEgClickstream } from '@shared-ui/clickstream-analytics-context';
import { UitkSpacing } from 'uitk-react-spacing';
import { Layout } from 'shared/components/layout/layout';
import { affiliateHubHomePresentedEvent } from 'shared/utils';
import { ApplyNow } from './components/apply-now';
import { Benefits } from './components/benefits';
import { BrandCards } from './components/brand-cards';
import { Brands } from './components/brands';
import { Flows } from './components/flows';
import { Hero } from './components/hero';
import { Products } from './components/products';
var HomePage = observer(
// tslint:disable-next-line:no-shadowed-variable
function HomePage() {
  var track = useEgClickstream();
  useEffect(function () {
    affiliateHubHomePresentedEvent(track);
  }, [track]);
  return React.createElement(Layout, null, React.createElement("section", null, React.createElement(Hero, null)), React.createElement(UitkSpacing, {
    padding: {
      block: 'twelve'
    }
  }, React.createElement("section", null, React.createElement(Brands, null))), React.createElement(UitkSpacing, {
    padding: {
      block: 'sixteen'
    },
    className: "shaded-section-bg"
  }, React.createElement("section", null, React.createElement(Benefits, null))), React.createElement(UitkSpacing, {
    padding: {
      block: 'twelve'
    }
  }, React.createElement("section", null, React.createElement(Flows, null))), React.createElement(UitkSpacing, {
    padding: {
      block: 'twelve'
    },
    className: "shaded-section-bg"
  }, React.createElement("section", null, React.createElement(BrandCards, null))), React.createElement(UitkSpacing, {
    padding: {
      block: 'twelve'
    }
  }, React.createElement("section", null, React.createElement(Products, null))), React.createElement(UitkSpacing, {
    padding: {
      block: 'twelve'
    },
    className: "shaded-section-bg"
  }, React.createElement("section", null, React.createElement(ApplyNow, null))));
});
export default HomePage;
export { HomePage };