import * as React from 'react';
import { usePlatformContext } from '@bernie/platform-context';
import { LocalizedText } from '@shared-ui/localization-context';
import { UitkLayoutFlex } from 'uitk-react-layout-flex';
import { UitkLink } from 'uitk-react-link';
import { UitkText } from 'uitk-react-text';
import { EventType, useCommonClickStreamEvent } from 'shared/utils';
export function Footer() {
  var context = usePlatformContext().context;
  var trackEvent = useCommonClickStreamEvent().trackEvent;
  return React.createElement("div", {
    className: "CVFooter"
  }, React.createElement("div", {
    className: "CVContainer"
  }, React.createElement(UitkLayoutFlex, {
    justifyContent: "space-between",
    space: "six",
    className: "CVFooter__content"
  }, React.createElement(UitkLayoutFlex, {
    className: "CVFooter__links",
    alignItems: "center",
    wrap: "wrap",
    space: "six"
  }, React.createElement(UitkLink, {
    onClick: function () {
      trackEvent({
        event_type: EventType.INTERACTION,
        event_name: 'creator_video_content_guidelines.selected',
        action_location: 'travel-video-landing-footer'
      });
    },
    inline: true,
    className: "CVFooter__button"
  }, React.createElement(UitkText, {
    size: 200
  }, React.createElement("a", {
    href: "https://affiliates.expediagroup.com/lp/b/affhub/affiliate?siteid=2055&locale=".concat(context.locale),
    role: "link"
  }, React.createElement(LocalizedText, {
    message: "creatorvideos.footer.link.terms_and_conditions"
  })))), React.createElement(UitkLink, {
    onClick: function () {
      trackEvent({
        event_type: EventType.INTERACTION,
        event_name: 'creator_video_content_guidelines.selected',
        action_location: 'travel-video-landing-footer'
      });
    },
    inline: true,
    className: "CVFooter__button"
  }, React.createElement(UitkText, {
    size: 200
  }, React.createElement("a", {
    href: '/travel-video/video-guidelines',
    role: "link"
  }, React.createElement(LocalizedText, {
    message: "creatorvideos.footer.link.video_content_guidelines"
  })))), React.createElement(UitkLink, {
    inline: true,
    className: "CVFooter__button"
  }, React.createElement(UitkText, {
    size: 200
  }, React.createElement("a", {
    href: "mailto:creatorvideosupport@expediagroup.com"
  }, React.createElement(LocalizedText, {
    message: "creatorvideos.footer.link.contact_us"
  }))))), React.createElement(UitkText, {
    size: 200
  }, React.createElement(LocalizedText, {
    message: "creatorvideos.footer.all_rights_reserved"
  })))));
}