import React from 'react';
import { LocalizedText } from '@shared-ui/localization-context';
import { UitkIcon } from 'uitk-react-icons';
import { UitkText } from 'uitk-react-text';
import { classNames } from 'uitk-react-utils';
import { NavMenu } from '../nav-menu/nav-menu';
function DesktopSideBar(_a) {
  var open = _a.open,
    onClick = _a.onClick;
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: classNames('SideBar', 'SideBar__desktop', open ? 'SideBar__desktop--open' : 'SideBar__desktop--closed'),
    "data-testid": "nav-links"
  }, React.createElement("nav", {
    className: "content-start"
  }, React.createElement("header", {
    role: "button",
    "aria-expanded": open,
    tabIndex: 0,
    className: "SideBar__toggle",
    onClick: onClick
  }, React.createElement("div", {
    className: "Sidebar__toggle"
  }, React.createElement(UitkIcon, {
    name: "chevron_right",
    className: classNames('SideBar__toggle-icon', open ? 'SideBar__toggle-icon--open' : 'SideBar__toggle-icon--closed')
  }), React.createElement(UitkText, {
    weight: "medium",
    inline: true,
    size: 300,
    className: classNames('SideBar__toggle-text', open ? 'SideBar__toggle-text--open' : 'SideBar__toggle-text--closed')
  }, React.createElement(LocalizedText, {
    message: "common.close_menu"
  })))), React.createElement(NavMenu, {
    open: open
  }))));
}
export { DesktopSideBar };