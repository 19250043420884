import React from 'react';
import { UitkBadgeStandard, UitkBadgeStandardThemes } from 'uitk-react-badge';
export function BetaBadge() {
  return React.createElement(UitkBadgeStandard, {
    className: "BetaBadge",
    text: "Beta",
    size: "small",
    a11yText: "Beta",
    theme: UitkBadgeStandardThemes.EMPHASIS
  });
}