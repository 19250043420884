import { Badges } from '../badges/badges';
export var navItems = [{
  section: 'misc',
  items: [{
    name: 'Application',
    icon: 'assignment',
    l10nKey: 'application.page.title',
    href: '/application'
  }, {
    name: 'Home',
    icon: 'home',
    l10nKey: 'common.home',
    href: '/'
  }, {
    name: 'performance',
    icon: 'trending_up',
    l10nKey: 'common.performance',
    href: '/performance'
  }, {
    name: 'CommissionReport',
    icon: 'monetization_on',
    text: 'Commission report',
    href: '/transaction'
  }, {
    name: 'Payouts',
    icon: 'payments',
    l10nKey: 'payouts.page.menu',
    href: '/payouts'
  }, {
    name: 'History',
    icon: 'history',
    l10nKey: 'product.link-history.title',
    href: '/link-history'
  }]
}, {
  section: 'tools',
  titleKey: 'dashboard.common.tools',
  items: [{
    name: 'CreatorToolbox',
    icon: 'toolbox',
    l10nKey: 'product.creator-toolbox.title',
    href: '/products/creator-toolbox'
  }, {
    name: 'TravelShops',
    text: 'Travel Shops',
    icon: 'local_shopping',
    href: '/products/travel-shops',
    badge: Badges.BETA
  }, {
    name: 'TravelVideo',
    text: 'Travel Videos',
    icon: 'ondemand_entertainment',
    href: '/products/travel-video',
    l10nKey: 'travelvideos.header',
    badge: Badges.BETA
  }, {
    name: 'LinkBuilder',
    icon: 'add_circle_outline',
    l10nKey: 'product.link-builder.title',
    href: '/products/link-builder'
  }, {
    name: 'Search',
    icon: 'search',
    l10nKey: 'products.search.title',
    href: '/products/search'
  }, {
    name: 'Banners',
    icon: 'map',
    l10nKey: 'products.banners.title',
    href: '/products/banners'
  }]
}];