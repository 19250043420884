import { APP_NAME, EventType } from 'shared/utils/click-stream-events';
import { ProfileController } from './profile/profile.controller';
var controller = new ProfileController();
export function accountTabSelected(_a) {
  var eventName = _a.eventName,
    userId = _a.userId,
    partnerId = _a.partnerId;
  return {
    event: {
      event_version: '1.0.0',
      event_name: eventName,
      event_type: EventType.INTERACTION,
      action_location: controller.path
    },
    experience: {
      page_name: controller.pageId,
      app_name: APP_NAME
    },
    user: {
      partner_account_id: partnerId !== null && partnerId !== void 0 ? partnerId : 'UNKNOWN',
      brand_customer_id: userId !== null && userId !== void 0 ? userId : 'ANONYMOUS'
    }
  };
}