// 3rd party imports
import * as React from 'react';
import { Link } from 'react-router-dom-v5-compat';
// shared-ui
import { useEgClickstream } from '@shared-ui/clickstream-analytics-context';
// ui
import { GlobalNavigationSiteHeader } from 'uitk-react-global-navigation';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { UitkLink } from 'uitk-react-link';
import { UitkSpacing } from 'uitk-react-spacing';
import { headerLogoSelectedEvent } from 'shared/utils';
import { LanguageSelector } from './language-selector';
import { SigninAndJoinNav } from './signin-and-join-nav';
var Header = function () {
  var track = useEgClickstream();
  return React.createElement(GlobalNavigationSiteHeader, {
    className: "Header"
  }, React.createElement(UitkSpacing, {
    padding: {
      inline: 'four'
    }
  }, React.createElement(UitkLayoutFlex, {
    className: "container",
    alignItems: "center",
    justifyContent: "space-between"
  }, React.createElement(UitkLayoutFlexItem, null, React.createElement("div", null, React.createElement(UitkLink, {
    onClickCapture: function () {
      return headerLogoSelectedEvent(track);
    }
  }, React.createElement(Link, {
    to: "/home"
  }, React.createElement("img", {
    className: "Header__logo",
    src: "/images/shared/eg-affiliate-program-logo-blue.svg",
    alt: "Expedia Group Affiliate Program"
  }))))), React.createElement(UitkLayoutFlexItem, null, React.createElement("nav", {
    className: "Header__nav"
  }, React.createElement(UitkLayoutFlex, {
    alignItems: "center",
    className: "no-bullet",
    direction: "row",
    space: "three",
    tag: "ul",
    wrap: "wrap"
  }, React.createElement("li", null, React.createElement(LanguageSelector, null)), React.createElement("li", null, React.createElement(SigninAndJoinNav, null))))))));
};
export { Header };