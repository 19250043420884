var BaseController = /** @class */function () {
  function BaseController(options) {
    if (options === void 0) {
      options = {};
    }
    var _a;
    this.requiresAuth = false;
    this.cookies = options.cookies || '';
    this.exact = (_a = options.exact) !== null && _a !== void 0 ? _a : true;
  }
  BaseController.prototype.getQueries = function (state, logger) {
    return this.queries.map(function (query) {
      return query(state, logger);
    });
  };
  // Do not use fetch. Set queries in the controller instead.
  BaseController.prototype.fetch = function () {
    return Promise.resolve({});
  };
  return BaseController;
}();
export { BaseController };