import { useMemo } from 'react';
import OktaAuth from '@okta/okta-auth-js';
import { useIsomorphicState } from './isomorphic-state';
var oktaAuthInstance = null;
export var useOktaAuth = function () {
  var okta = useIsomorphicState(function (state) {
    return state.appConfig;
  }).okta;
  var oktaAuth = useMemo(function () {
    if (typeof window === 'undefined' || !okta) {
      return null;
    }
    // Initialize the singleton OktaAuth instance if it hasn't been created
    if (!oktaAuthInstance) {
      oktaAuthInstance = new OktaAuth({
        issuer: okta.issuer,
        clientId: okta.clientId,
        redirectUri: okta.redirectUri,
        scopes: okta.scopes,
        tokenManager: {
          storage: 'cookie',
          autoRenew: true,
          autoRemove: true
        }
      });
    }
    return oktaAuthInstance;
  }, [okta]);
  return oktaAuth;
};