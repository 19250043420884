var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
      label: 0,
      sent: function () {
        if (t[0] & 1) throw t[1];
        return t[1];
      },
      trys: [],
      ops: []
    },
    f,
    y,
    t,
    g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;
  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }
  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");
    while (g && (g = 0, op[0] && (_ = 0)), _) try {
      if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
      if (y = 0, t) op = [op[0] & 2, t.value];
      switch (op[0]) {
        case 0:
        case 1:
          t = op;
          break;
        case 4:
          _.label++;
          return {
            value: op[1],
            done: false
          };
        case 5:
          _.label++;
          y = op[1];
          op = [0];
          continue;
        case 7:
          op = _.ops.pop();
          _.trys.pop();
          continue;
        default:
          if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
            _ = 0;
            continue;
          }
          if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
            _.label = op[1];
            break;
          }
          if (op[0] === 6 && _.label < t[1]) {
            _.label = t[1];
            t = op;
            break;
          }
          if (t && _.label < t[2]) {
            _.label = t[2];
            _.ops.push(op);
            break;
          }
          if (t[2]) _.ops.pop();
          _.trys.pop();
          continue;
      }
      op = body.call(thisArg, _);
    } catch (e) {
      op = [6, e];
      y = 0;
    } finally {
      f = t = 0;
    }
    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};
import React from 'react';
import { Link } from 'react-router-dom-v5-compat';
import { LocalizedText } from '@shared-ui/localization-context';
import { UitkSecondaryButton } from 'uitk-react-button';
import { UitkIcon } from 'uitk-react-icons';
import { UitkText } from 'uitk-react-text';
import { LanguageSelector } from '../../../components/layout/language-selector';
import { useIsomorphicState } from '../../../hooks/isomorphic-state';
import { useLayoutNavigation } from '../../hooks/use-layout-navigation';
import { AccountNav } from '../account-nav/account-nav';
export function TopBarDesktop(_a) {
  var landingUrl = _a.landingUrl;
  var user = useIsomorphicState(function (state) {
    return state.user;
  });
  var hasRestrictedAccess = useLayoutNavigation(user).hasRestrictedAccess;
  var isAccountLinkingEnabled = true;
  function handleOpenCreatorToolbox() {
    return __awaiter(this, void 0, void 0, function () {
      return __generator(this, function (_a) {
        if (isAccountLinkingEnabled) {
          // window.open(stripeUrl, '_blank');
        } else {
          // Note: this is to prevent mobile web browser redirecting to mobile app
          window['popup'] = window.open('', '_blank');
          setTimeout(function () {
            // window['popup'].location.assign(stripeUrl);
          }, 1000);
        }
        return [2 /*return*/];
      });
    });
  }
  return React.createElement("header", {
    className: "TopBar"
  }, React.createElement("div", {
    className: "TopBar__header"
  }, React.createElement(Link, {
    to: landingUrl
  }, React.createElement("img", {
    src: "/images/logos/logo-white.png",
    className: "TopBar__logo",
    alt: "Expedia Group Affiliate Program"
  }))), React.createElement("div", {
    className: "TopBar__nav"
  }, hasRestrictedAccess !== true && React.createElement(UitkSecondaryButton, {
    disabled: false /*!stripeUrl || !isUserPrefsLoaded || isLoading*/,
    onClick: handleOpenCreatorToolbox,
    inverse: true,
    className: "TopBar__creatorToolbox"
  }, React.createElement(React.Fragment, null, React.createElement(UitkIcon, {
    className: "TopBar__creatorToolbox--icon",
    name: "toolbox"
  }), React.createElement(UitkText, {
    weight: "bold",
    size: 200
  }, React.createElement(LocalizedText, {
    message: "common.creator_toolbox"
  })))), React.createElement(LanguageSelector, {
    compact: true
  }), React.createElement(AccountNav, null)));
}