var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
// import { useExperiment } from '@shared-ui/experiment-context';
// import { ExperimentName } from 'shared/experiment';
import React, { useMemo } from 'react';
import { useLocalization } from '@shared-ui/localization-context';
import { UitkText } from 'uitk-react-text';
import { classNames } from 'uitk-react-utils';
import { useIsomorphicState } from '../../../hooks/isomorphic-state';
import { useLayoutNavigation } from '../../hooks/use-layout-navigation';
import { NavItem } from '../nav-item/nav-item';
import { navItems } from '../nav-item/nav-items';
export var NavMenu = function (_a) {
  var open = _a.open;
  var user = useIsomorphicState(function (state) {
    return state.user;
  });
  var hasRestrictedAccess = useLayoutNavigation(user).hasRestrictedAccess;
  var l10n = useLocalization();
  // const { isCreatorShopsEnabled } = useCreatorShopEnabledQuery();
  var isCreatorShopsEnabled = false;
  // checks for certain experiments
  // const { exposure: removePzExposure } = useExperiment(ExperimentName.REMOVE_PZ_V1);
  var isRemovePzExperimentEnabled = false; //removePzExposure?.bucket === 1;
  // const { exposure: linkToolBarExposure } = useExperiment(ExperimentName.LINK_TOOLBAR);
  var isLinkToolbarExperimentEnabled = false; //linkToolBarExposure?.bucket === 1;
  var isTransactionAllowed = true; // localStorage.getItem('transaction');
  var showPayouts = ((user === null || user === void 0 ? void 0 : user.hasPzPartnerApiKey) || (user === null || user === void 0 ? void 0 : user.isNetworkUser)) && isRemovePzExperimentEnabled;
  // const { exposure: tsExposure } = useExperiment(ExperimentName.TRAVEL_SHOPS);
  var isTsExperimentEnabled = false; //tsExposure?.bucket === 1;
  // process nav items to determine if they should be hidden
  var determineIfHidden = function (item) {
    if (!isTsExperimentEnabled && !isCreatorShopsEnabled && item.name === 'TravelShops') {
      return true;
    }
    if (!hasRestrictedAccess && item.name === 'Application') {
      return true;
    }
    if (!isTransactionAllowed && item.name === 'CommissionReport') {
      return true;
    }
    if (!isLinkToolbarExperimentEnabled && item.name === 'CreatorToolbox') {
      return true;
    }
    if (!showPayouts && item.name === 'Payouts') {
      return true;
    }
    return false;
  };
  var processedNavItems = useMemo(function () {
    return navItems.map(function (section) {
      var items = section.items.filter(function (item) {
        return !determineIfHidden(item);
      });
      return __assign(__assign({}, section), {
        items: items
      });
    });
  }, [navItems, hasRestrictedAccess, isTransactionAllowed, isLinkToolbarExperimentEnabled, showPayouts, isCreatorShopsEnabled]);
  return React.createElement("div", {
    className: "NavMenu"
  }, processedNavItems.map(function (section, i) {
    var navListItems = section.items.map(function (item, j) {
      var l10nKey = item.l10nKey,
        href = item.href,
        icon = item.icon,
        name = item.name,
        text = item.text,
        badge = item.badge;
      return React.createElement(NavItem, {
        name: name,
        key: "nav-item-".concat(j),
        text: l10nKey ? l10n.formatText(l10nKey) : text,
        to: href,
        icon: icon,
        showText: open,
        isDisabled: hasRestrictedAccess,
        badge: badge
      });
    });
    return React.createElement("div", {
      key: "nav-section-".concat(i)
    }, section.titleKey && React.createElement("header", {
      className: "NavMenu__section"
    }, React.createElement(UitkText, {
      className: classNames({
        hidden: !open
      }),
      weight: "bold",
      size: 200
    }, l10n.formatText(section.titleKey))), React.createElement("ol", null, navListItems));
  }));
};