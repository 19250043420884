import React from 'react';
import { useLocalization } from '@shared-ui/localization-context';
import { UitkSpacing } from 'uitk-react-spacing';
import { AuthenticatedLayout } from 'shared/authenticated-layout/authenticated-layout';
import { PageHeader } from 'shared/components/page-header';
import { AccountTabs } from '../components/account-tabs';
export function AccountShell(_a) {
  var children = _a.children;
  var l10n = useLocalization();
  return React.createElement(AuthenticatedLayout, null, React.createElement(UitkSpacing, {
    margin: 'twelve'
  }, React.createElement("div", {
    className: "container"
  }, React.createElement(PageHeader, {
    title: l10n.formatText('account.page.title')
  }), React.createElement(AccountTabs, null), children)));
}