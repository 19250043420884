import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom-v5-compat';
import { useEgClickstream } from '@shared-ui/clickstream-analytics-context';
import { useLocalization } from '@shared-ui/localization-context';
import { UitkNavigationType, UitkTab, UitkTabs, UitkTabsBackGroundColorType, UitkTabsType } from 'uitk-react-tabs';
import { useIsomorphicState } from 'shared/hooks/isomorphic-state';
import { accountTabSelected } from '../account.events';
import { BANK_DETAILS_PATH } from '../bank-details/bank-details.controller';
import { PROFILE_PATH } from '../profile/profile.controller';
import { REFERRAL_PATH } from '../referral/referral.controller';
import { SETTINGS_PATH } from '../settings/settings.controller';
export function AccountTabs() {
  var track = useEgClickstream();
  var navigate = useNavigate();
  var pathname = useLocation().pathname;
  var l10n = useLocalization();
  var _a = useIsomorphicState(function (state) {
      return state;
    }).user,
    creationTimestamp = _a.creationTimestamp,
    isNetworkUser = _a.isNetworkUser,
    hasPzPartnerApiKey = _a.hasPzPartnerApiKey,
    id = _a.id,
    partnerId = _a.partnerId;
  // Pulled release date from the old app - not sure if its still needed
  // TODO: set this as the release date of payment flow when l10n is ready
  var userCreationDate = new Date(creationTimestamp);
  var releaseDate = new Date('2024-01-24');
  var isPaymentEligible = userCreationDate > releaseDate && (isNetworkUser || hasPzPartnerApiKey);
  var tabs = [{
    id: 'profile',
    name: l10n.formatText('account.tab-navigation.profile'),
    path: PROFILE_PATH,
    event: 'affhub_account_profile.selected'
  }, {
    id: 'referral',
    name: 'Referral' /* TODO; Localisation */,
    path: REFERRAL_PATH,
    event: 'affhub_account_referral.selected'
  }, isPaymentEligible ? {
    id: 'bank-details',
    name: l10n.formatText('account.tab-navigation.bank_details'),
    path: BANK_DETAILS_PATH,
    event: 'affhub_account_bank_details.selected'
  } : null, {
    id: 'settings',
    name: l10n.formatText('account.tab-navigation.settings'),
    path: SETTINGS_PATH,
    event: 'affhub_account_settings.selected'
  }].filter(Boolean);
  var handleSelectedTab = function (targetTab, e) {
    e.preventDefault();
    var _a = tabs[targetTab],
      event = _a.event,
      path = _a.path;
    navigate(path);
    track(accountTabSelected({
      eventName: event,
      userId: id,
      partnerId: partnerId
    }));
  };
  return React.createElement(UitkTabs, {
    tabsType: UitkTabsType.NATURAL,
    backgroundColor: UitkTabsBackGroundColorType.SECONDARY,
    onTabSelect: handleSelectedTab,
    className: "mb-6",
    navigationType: UitkNavigationType.JUMPLINK,
    withBorder: true,
    selectedTab: tabs.findIndex(function (tab) {
      return tab.path === pathname;
    })
  }, tabs.map(function (tab) {
    return React.createElement(UitkTab, {
      targetURI: tab.path,
      key: tab.id,
      name: tab.name,
      id: tab.id
    });
  }));
}