// 3rd party imports
import * as React from 'react';
// ui
import { LocalizedText } from '@shared-ui/localization-context';
import { UitkFigure, UitkImage } from 'uitk-react-images';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { UitkSpacing } from 'uitk-react-spacing';
import { UitkHeading } from 'uitk-react-text';
import { BrandItems } from './brand-cards-items';
function Brands() {
  return React.createElement("div", {
    className: "Brands container"
  }, React.createElement(UitkHeading, {
    tag: "h2",
    size: 3
  }, React.createElement(LocalizedText, {
    message: "home.brands.heading.access_to_brands"
  })), React.createElement(UitkSpacing, {
    padding: {
      block: 'six'
    }
  }, React.createElement(UitkLayoutFlex, {
    className: "Brands__list",
    justifyContent: "space-between",
    wrap: "wrap",
    space: "four"
  }, BrandItems().map(function (item, i) {
    return React.createElement(UitkLayoutFlexItem, {
      key: i
    }, React.createElement(UitkFigure, {
      imageFit: "contain",
      className: "Brands__logo"
    }, React.createElement(UitkImage, {
      alt: item.brand,
      src: item.logo
    })));
  }))));
}
export { Brands };