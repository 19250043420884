var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
import { useLocation, useParams } from 'react-router-dom-v5-compat';
import { useQuery } from '@tanstack/react-query';
import { useIsomorphicState } from './isomorphic-state';
import { useLogger } from './use-logger';
export var useIsomorphicQuery = function (queryFunc) {
  var logger = useLogger();
  var appState = useIsomorphicState(function (state) {
    return state;
  });
  var queryParams = new URLSearchParams(useLocation().search);
  var entries = Array.from(queryParams.entries());
  var query = Object.fromEntries(entries);
  var params = useParams();
  var _a = queryFunc(__assign(__assign({}, appState), {
      params: params,
      query: query
    }), logger),
    queryKey = _a.queryKey,
    queryFn = _a.queryFn;
  return useQuery({
    queryKey: queryKey,
    queryFn: queryFn
  });
};