import React from 'react';
import { UitkSpacing } from 'uitk-react-spacing';
import { UitkHeading, UitkText } from 'uitk-react-text';
export var PageHeader = function (_a) {
  var children = _a.children,
    title = _a.title,
    description = _a.description,
    notificationContent = _a.notificationContent,
    badge = _a.badge;
  var Title = function () {
    return React.createElement(UitkHeading, {
      size: 3
    }, title);
  };
  return React.createElement("header", {
    "data-testid": "PageHeader",
    className: "PageHeader"
  }, notificationContent && React.createElement(UitkSpacing, {
    margin: {
      blockend: 'six'
    }
  }, notificationContent), badge ? React.createElement("div", null, React.createElement(Title, null), badge) : React.createElement(Title, null), description && React.createElement(UitkText, {
    size: 300,
    weight: "regular"
  }, description), children);
};