var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
import { useState } from 'react';
function useMenu() {
  var _a = __read(useState(false), 2),
    menuOpen = _a[0],
    setInternalMenuOpen = _a[1];
  function setMenuOpen(isOpen) {
    // With version 10, UITK Menu doesn't invoke toggle A if another menu B is clicked
    // and causes multiple menus opened at the same time
    // Issue is not replicable when setting breakpoints or using setTimeout
    // TODO: remove setTimeout once a proper solution is found
    var timeoutRef = setTimeout(function () {
      setInternalMenuOpen(isOpen);
      clearTimeout(timeoutRef);
    }, 0);
  }
  return {
    menuOpen: menuOpen,
    setMenuOpen: setMenuOpen
  };
}
export { useMenu };