// 3rd party imports
import * as React from 'react';
// ui
import { LocalizedText } from '@shared-ui/localization-context';
// components
import { UitkButtonSize } from 'uitk-react-button';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { UitkHeading, UitkText } from 'uitk-react-text';
import { JoinNowAction } from 'shared/components/layout/join-now-action';
var ApplyNow = function () {
  return React.createElement("div", {
    className: "ApplyNow container"
  }, React.createElement(UitkLayoutFlex, {
    space: "six",
    direction: "column",
    alignItems: "center"
  }, React.createElement(UitkLayoutFlexItem, null, React.createElement(UitkHeading, {
    tag: "h1",
    size: 4,
    align: "center"
  }, React.createElement(LocalizedText, {
    message: "home.apply-now.heading.apply_now"
  }))), React.createElement(UitkLayoutFlexItem, null, React.createElement(UitkText, {
    className: "ApplyNow__descritpion",
    align: "center"
  }, React.createElement(LocalizedText, {
    message: "home.apply-now.content.reach_bigger_audience"
  }))), React.createElement(UitkLayoutFlexItem, null, React.createElement(JoinNowAction, {
    size: UitkButtonSize.LARGE,
    actionLocation: "page_bottom"
  }))));
};
export { ApplyNow };