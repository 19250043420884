// 3rd party imports
import * as React from 'react';
import { useWindowSize } from 'react-use';
// ui
import { LocalizedText } from '@shared-ui/localization-context';
import { UitkFigure, UitkImage } from 'uitk-react-images';
import { UitkLayoutGrid, UitkLayoutGridItem } from 'uitk-react-layout-grid';
import { UitkSpacing } from 'uitk-react-spacing';
import { UitkHeading, UitkText } from 'uitk-react-text';
function Products() {
  var linksImgSrc = '/images/home/UI_Links.png';
  var searchWidgetImgSrc = '/images/home/UI_SearchWidget.png';
  var bannersImgSrc = '/images/home/UI_Banners.png';
  var creatorToolboxSrc = '/images/home/UI_CreatorToolbox.jpg';
  var performanceSrc = '/images/home/UI_Performance.png';
  var width = useWindowSize().width;
  var isMobile = width < 768;
  return React.createElement("div", {
    className: "Products container"
  }, React.createElement(UitkSpacing, {
    padding: {
      blockend: 'six'
    }
  }, React.createElement(UitkHeading, {
    tag: "h2",
    size: 3
  }, React.createElement(LocalizedText, {
    message: "home.products.heading.all_tools_you_need"
  }))), React.createElement(UitkSpacing, {
    padding: {
      block: 'six'
    }
  }, React.createElement(UitkLayoutGrid, {
    columns: {
      medium: ['1fr', '1fr'],
      small: ['1fr']
    },
    space: "twelve",
    alignItems: "center"
  }, React.createElement(UitkLayoutGridItem, null, React.createElement(UitkFigure, {
    imageFit: "contain",
    className: "Products__image"
  }, React.createElement(UitkImage, {
    alt: "Creator Toolbox",
    src: creatorToolboxSrc
  }))), React.createElement(UitkLayoutGridItem, null, React.createElement("div", null, React.createElement(UitkHeading, {
    size: 4,
    tag: "h2"
  }, React.createElement(LocalizedText, {
    message: "product.creator-toolbox.title"
  })), React.createElement(UitkText, {
    spacing: "two"
  }, React.createElement(LocalizedText, {
    message: "home.products.creator-toolbox"
  })))))), React.createElement(UitkSpacing, {
    padding: {
      block: 'twelve'
    }
  }, React.createElement(UitkLayoutGrid, {
    areas: isMobile ? ['thumbnail', 'text'] : ['text thumbnail'],
    columns: {
      medium: ['1fr', '1fr'],
      small: ['1fr']
    },
    space: "twelve",
    alignItems: "center"
  }, React.createElement(UitkLayoutGridItem, {
    area: "text"
  }, React.createElement("div", null, React.createElement(UitkHeading, {
    size: 4,
    tag: "h2"
  }, React.createElement(LocalizedText, {
    message: "performance-charts.page.title"
  })), React.createElement(UitkText, {
    spacing: "two"
  }, React.createElement(LocalizedText, {
    message: "home.products.performance"
  })))), React.createElement(UitkLayoutGridItem, {
    area: "thumbnail"
  }, React.createElement(UitkFigure, {
    imageFit: "contain",
    className: "Products__image"
  }, React.createElement(UitkImage, {
    alt: "Performance",
    src: performanceSrc
  }))))), React.createElement(UitkSpacing, {
    padding: {
      block: 'six'
    }
  }, React.createElement(UitkLayoutGrid, {
    columns: {
      medium: ['1fr', '1fr', '1fr'],
      small: ['1fr']
    },
    space: "twelve",
    alignItems: "center"
  }, React.createElement(UitkLayoutGrid, {
    rows: {
      medium: ['2fr', '1fr'],
      small: ['1fr']
    },
    space: "twelve",
    alignItems: "center"
  }, React.createElement(UitkLayoutGridItem, null, React.createElement(UitkFigure, {
    imageFit: "contain",
    className: "Products__image"
  }, React.createElement(UitkImage, {
    alt: "Links preview",
    src: linksImgSrc
  }))), React.createElement(UitkLayoutGridItem, null, React.createElement("div", null, React.createElement(UitkHeading, {
    size: 4,
    tag: "h2"
  }, React.createElement(LocalizedText, {
    message: "products.links.title"
  })), React.createElement(UitkText, {
    spacing: "two"
  }, React.createElement(LocalizedText, {
    message: "home.products.deeplinks.description"
  }))))), React.createElement(UitkLayoutGrid, {
    rows: {
      medium: ['2fr', '1fr'],
      small: ['1fr']
    },
    space: "twelve",
    alignItems: "center"
  }, React.createElement(UitkLayoutGridItem, null, React.createElement(UitkFigure, {
    imageFit: "contain",
    className: "Products__image"
  }, React.createElement(UitkImage, {
    alt: "Search Widget preview",
    src: searchWidgetImgSrc
  }))), React.createElement(UitkLayoutGridItem, null, React.createElement("div", null, React.createElement(UitkHeading, {
    size: 4,
    tag: "h2"
  }, React.createElement(LocalizedText, {
    message: "products.search.title"
  })), React.createElement(UitkText, {
    spacing: "two"
  }, React.createElement(LocalizedText, {
    message: "home.products.search-widget.description"
  }))))), React.createElement(UitkLayoutGrid, {
    rows: {
      medium: ['2fr', '1fr'],
      small: ['1fr']
    },
    space: "twelve",
    alignItems: "center"
  }, React.createElement(UitkLayoutGridItem, null, React.createElement(UitkFigure, {
    imageFit: "contain",
    className: "Products__image"
  }, React.createElement(UitkImage, {
    alt: "Banners preview",
    src: bannersImgSrc
  }))), React.createElement(UitkLayoutGridItem, null, React.createElement("div", null, React.createElement(UitkHeading, {
    size: 4,
    tag: "h2"
  }, React.createElement(LocalizedText, {
    message: "products.banners.title"
  })), React.createElement(UitkText, {
    spacing: "two"
  }, React.createElement(LocalizedText, {
    message: "home.products.banners.description"
  }))))))));
}
export { Products };